@import "src/styles/helpers/variables/palette";
@import "src/styles/helpers/variables/base";
body{
    color: $rws-primary;
    font-family: $inter, sans-serif!important;
    
}
button[disabled] {
    background-color: $rws-disable;
    color: $rws-text-disable;
    &:hover{
        background-color: $rws-disable !important;
    }
}
.primary{
    height: 44px !important;
    background-color: var(--primary-color);
    padding: 10px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400; /* Medium weight */
    font-size: 14px; /* Font size of 14 pixels */
    letter-spacing: 0.03em; /* 3% letter spacing */
    text-decoration: none; /* No text decoration */
    line-height: normal; /* Automatic line height */
    text-transform: none; /* Original case */
    color: #fff;
    border: none;
    transition: background-color 0.3s;
}
.primary:hover {
    background-color: #010937 !important; /* Hover effect color */
}
.primary:focus, .secondary-btn:focus, .secondary:focus, .accept:focus, .reject:focus, .sd-btn:focus, .sd-item__control:focus, button:focus {
    outline: 2px solid #999;
    outline-offset: 2px;
}
.sd-radio__control:focus {
    + .sd-item__decorator {
        outline: 2px solid #999;
        outline-offset: 2px;
    }
}
.document-item {
    a:focus {
        outline: 2px solid #999;
        outline-offset: 2px;
    }
}
.secondary{
    height: 44px !important;
    background-color: #08135114;
    width: 20%;
    border: none;
    margin: 10px;
    color: var(--primary-color);
    border-radius: 100px;
    height: 44px;
    padding: 10px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500; /* Medium weight */
    font-size: 14px; /* Font size of 14 pixels */
    letter-spacing: 0.03em; /* 3% letter spacing */
    text-decoration: none; /* No text decoration */
    line-height: normal; /* Automatic line height */
    text-transform: none; /* Original case */
    border: none;
  }
.secondary-btn{
    // background-color: var(--secondary-color) !important;
    background-color: var(--secondary-color) !important;
    height: 44px;
    padding: 10px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500; /* Medium weight */
    font-size: 14px; /* Font size of 14 pixels */
    letter-spacing: 0.03em; /* 3% letter spacing */
    text-decoration: none; /* No text decoration */
    line-height: normal; /* Automatic line height */
    text-transform: none; /* Original case */
    color: #fff;
    border: none;
}
.secondary-btn:hover, .secondary-btn:hover {
    background-color: #E25D0B !important; /* Hover effect color */
}
.green-btn:hover{
    background-color: #017673 !important;
  }

h1 {
    font-family: $raisonne, sans-serif;
    font-size: 32px !important;
    font-weight: 600; /* DemiBold weight */
    line-height: 32px; /* Line height of 32 pixels */
    letter-spacing: 0; /* 0% letter spacing */
    color: var(--primary-color);
    margin: 0 !important;
}

h2, .heading-xlarge {
    font-family: $inter, sans-serif!important;
    font-size: 24px !important;
    font-weight: 500; /* Medium weight */
    line-height: 32px; /* Line height of 32 pixels */
    letter-spacing: 0.5%; /* 0.5% letter spacing */
    text-decoration: none; /* No text decoration */
    text-transform: none; /* Original case */
    color: var(--primary-color);
    margin: 0 !important;
    margin-bottom: 0 !important;
}

h3 {
    font-family: $inter, sans-serif!important;
    font-weight: 400; /* Medium weight */
    font-size: 18px !important;
    line-height: 24px !important; /* Line height of 24 pixels */
    letter-spacing: 0.5%; /* 0.5% letter spacing */
    text-decoration: none; /* No text decoration */
    text-transform: none; /* Original case */
    margin: 0 !important;
}

h4 {
    font-family: $inter, sans-serif!important;
    font-size: 16px !important;
    font-weight: 500; /* Medium weight */
    line-height: 24px; /* Line height of 24 pixels */
    letter-spacing: 0; /* 0% letter spacing */
    color: #7D8A95;
    margin: 0 !important;

}

h5 {
    font-family: $inter, sans-serif!important;
    font-size: 12px !important;
    font-weight: 500; /* Medium weight */
    line-height: 24px; /* Line height of 24 pixels */
    letter-spacing: 0.05em !important; /* 5% letter spacing */
    text-transform: uppercase; /* Convert text to uppercase */
    margin: 0 !important;
}

.header {
    .header-container {
        background: $rws-primary;
        border: none;
            .rightNav {
                a {
                    color: #fff;
                }
            }
        .header-link-home {
            .header-logotype {
                img {
                    filter: brightness(0) invert(1);
                }
            }
        }    
    }
}
.header .navigation-item {
    font-family: $inter, sans-serif!important;
}
.success-message{
    font-size: 14px;
    border: 1px solid #00A09B;
    color: #00A09B;
    padding: 1em;
    background-color: rgba(0,160,155,0.16);
    font-weight: 500;
    border-radius: 5px;
    font-family: $inter;
  }

.error-message{
    font-size: 14px;
    border: 1px solid red;
    color: red;
    padding: 1em;
    background-color: rgba(0,160,155,0.16);
    font-weight: 500;
    border-radius: 5px;
    font-family: $inter;
}
.mat-mdc-form-field-error {
    color: #A01308!important;
}  
mat-error{
    margin-top: 5px!important;
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) {
    .mdc-floating-label {
        color: #A01308!important;
    }
}
.uploadFile{
    .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
        color: #7D8A95 !important;
    }
}

mat-hint{
    font-size: 12px !important;
    color: #7D8A95 !important;
    font-family: $inter, sans-serif !important;
    font-weight: 500 !important;
}
.sd-body{
    background-color: #fff;
    border-radius: 0 0 12px 12px;
}

.sd-body.sd-body--responsive{
    padding: 2em 4em !important;
    @media screen and (max-width: 480px) {
        padding-bottom: 60px!important;
    }
    .sd-page{
        padding: 0 !important;
    }
}
.sd-matrix {
    table{
        border: 1px solid #EBEBEB !important;
    }
    .sd-title{
        color: var(--primary-color) !important;
    }
    span .sv-string-viewer{
        color: var(--primary-color) !important;
        
    }
    .sd-table__cell--header{
        .sv-string-viewer{
            font-size: 12px !important;
            text-transform: uppercase !important;
        }
    }

    .sd-header__text .sd-title {
        color: var(--primary-color);
    }
    tr th .sd-table__cell{
        border-bottom: none !important;
    }
    td .sd-table__cell{
        border-bottom: none !important;
    }
    div .sd-question__content{
        padding: 0 !important;
        // border: 1px solid rgba(125, 138, 149, 1);
    }

    th.sd-table__cell {
        color: rgba(125, 138, 149, 1) !important;
        font-family: $inter !important;
        font-weight: 400;

    }

    tr td .sv-string-viewer{
        color: var(--primary-color) !important;
        font-family: $inter !important;
        font-weight: 400;
        font-size: 14px;
    }
    
    // tr td{
    //     background-color: rgba(250, 250, 250, 1) !important;

    // }
    thead {
        background-color: rgba(250, 250, 250, 1) !important;
    }
    thead th:last-child {
        border-radius: 0 8px 0 0 !important;
    }
    tr th{
        background-color: rgba(250, 250, 250, 1) !important;
    }

    div .sd-table-wrapper{
        display: block;
    }
    tr .table__cell--row-text{
        background-color: rgba(250, 250, 250, 1) !important;
    }
    div .sd-element--with-frame{
        border: 1px solid rgba(250, 250, 250, 1);
        border-radius: 10px;
        padding-left: 0 !important;
    }
    td .sd-table__cell{
        width: 100%;
    }
    .sd-table thead tr td:nth-child(odd) {
        border-color: transparent !important;
        background-color: rgba(250, 250, 250, 1) !important;
        border-radius: 8px 0 0 0;
    }
    // .sd-table thead tr:nth-child(odd) {
    //     border-color: transparent !important;
    //     background-color: rgba(250, 250, 250, 1) !important;
    // }
    .sd-table thead tr th {
        border-bottom: 20px !important;
    }
    .sd-table{
        border-radius: 8px;
    }
    .sv-components-row {
        background-color: #fff;
    }
    // for radio border after selection
    // .sd-item--disabled.sd-item--disabled .sd-item__decorator, .sd-item__decorator {
    //     border: 1px solid #EBEBE9;
    //     box-shadow: none;
    // }
   
    .sd-item--checked .sd-item__decorator, .sd-item__control:focus+.sd-item__decorator, .sd-item--checked .sd-item__decorator {
        background: var(--secondary-color) !important;
        box-shadow : 0 0 0 1px var(--secondary-color) !important;
    }
    span .sd-radio--checked .sd-radio__decorator:before {
        background: var(--secondary-color) !important;
    }
    .sd-radio--checked .sd-radio__control:focus+.sd-radio__decorator::before {
        background-color: #fff !important;
        box-shadow : 0 0 0 1px var(--secondary-color) !important;

    }
    .sd-radio--checked .sd-radio__control:focus+.sd-radio__decorator:after {
        background-color: #fff!important;
        box-shadow : 0 0 0 1px var(--secondary-color) !important;

    }

    // .sd-radio--checked .sd-radio__decorator {
    //     box-shadow : 0 0 0 2px orange !important;
    // }
    .sd-radio__decorator {
        // border: 1px solid rgba(8, 19, 81, 0.16);
        width: 16px;
        height: 16px;
    }

    // color for odd row 
    .sd-table__row:nth-child(even) {
        border-color: transparent !important;
        background-color: rgba(250, 250, 250, 1) !important;
    }
    .sd-page__title~.sd-row.sd-page__row:not(.sd-row--compact) {
        border: 1px solid #f1f1f1 !important;
        border-radius: 10px !important;
    }
    // .sd-matrix__table .sd-matrix__cell:nth-child(even) {
    //     background-color: rgb(214, 21, 21) !important;
    // }
    .sd-matrix__table{
        tr:nth-child(2n) {
            .sd-matrix__cell {
                background-color: rgba(250, 250, 250, 1) !important;
            }
        }
    }

    .sd-table-wrapper:before, .sd-table-wrapper:after {
        display: none; 
    }
    .sd-matrix__table  {
        tr:nth-child(odd) {
            .sd-matrix__cell{
                background-color: #fff !important;
            //     color: var(--secondary-color);
                .sd-table__cell--row-text{
                    width: 100%;
                }
            }
        }
    }
// fieldset{
//     padding-left: 3em !important;
//     padding-right: 3em !important;
// }
    
    
    
}
.sd-html{
    line-height: 20px !important;
    margin-top: 8px !important;
    p{
        color: var(--primary-color) !important;
    }
}
.sd-table-wrapper::before{
    display: none !important;
}
.sd-table-wrapper::after{
    display: none !important;
}


.sd-root-modern:not(.sd-root-modern--mobile) .sd-table__cell:not(.sd-table__cell--actions):not(.sd-table__cell--action):not(.sd-table__cell--empty.sd-table__cell--error) {
    width: 100% !important;
    border: none !important;
    
}

.sd-title{
    color: var(--primary-color) !important;
    font-family: $inter !important;
    font-size: 18px !important;
    .sd-element--complex>.sd-element__header:after{
        background-color: #fff!important;
    }
    
    
}
.sd-container-modern{
    padding: 2em 0;
}
.sd-container-modern__title{
    border-radius: 12px 12px 0 0 !important;
    padding-left: 15px !important;
    box-shadow: none !important;
    border-bottom: 1px solid #fff !important;
    // margin-top: 2em;
}
.sd-row {
    .sd-element--with-frame {
        border: 1px solid #EBEBEB;
        padding-bottom: 1em !important;
        border-radius: 8px !important;
    }
    
    .sd-question.sd-question--table {
        overflow-x: visible !important;
    }
    .sd-row__question{
        // padding-top: 1em !important;  
    }
    .sd-clearfix .sd-page__row {
        overflow-x: auto !important;
        border: 1px solid green !important;
        border-radius: 8px !important;
        }
    .sd-panel__content{
        padding-top: 0 !important;
    }
    .sd-element--complex{
        // padding-top: 1em !important;
    }
}

.sd-row > div:first-child {
    overflow: auto !important;
}

.sd-question--table{
    .sd-question__content{
        padding-top: 0 !important;
    }
}
legend+.sd-rating__item,
legend+sv-ng-rating-item-smiley+.sd-rating__item-smiley,
legend+sv-ng-rating-item+.sd-rating__item {
    margin-inline-start: 0 !important;
    // border: 1px solid #EBEBEB !important;
    
}
.sd-rating__item{
    height: 32px !important;
    width: 32px !important;
    min-width: 0 !important;
    margin-right: 10px !important;
    border: 1px solid #EBEBEB !important;
    box-shadow: none !important;
    .sd-rating__item-text{
        .sv-string-viewer{
            display: inline !important;
            color: #7D8A95 !important;
            font-size: 14px !important;
            font-weight: 500 !important;
        }
    }
}
.sv-list__item{
    .sv-list__item-body{
        .sd-rating-dropdown-item{
            .sd-rating-dropdown-item_text{
                color: var(--primary-color) !important;
            }
        }
    }
}
.sv-list__item--selected{
    .sv-list__item-body{
        .sd-rating-dropdown-item{
            .sd-rating-dropdown-item_text{
                color: #fff !important;
            }
        }
    } 
}
.sd-rating__item--selected{
        .sd-rating__item-text{
            .sv-string-viewer{
                color: #fff !important;
            }
    }
}
.sd-element__num{
    font-size: 16px !important;
    font-weight: 700 !important;
    color: var(--primary-color) !important;
    float: none !important;
    margin-inline-start: 0 !important;
    padding-inline-end: 0 !important
}

.sv-string-viewer--multiline{
    font-weight: 700 !important;
    text-transform: none;
    letter-spacing: normal !important;
    padding-left: 3px;
}

.sv-action{
    .sd-btn{
        background-color: var(--primary-color) !important;
        color: #FFFFFF !important;
        display: flex !important;      /* Ensure flex layout */
        justify-content: center !important; /* Center content horizontally */
        align-items: center !important; 
        border-radius: 100px !important;
    }
    .sd-btn:focus-visible {
        box-shadow: none !important;
    }

    .sd-navigation__prev-btn{
        background-color: #08135114 !important;
        border: none !important;
        color: var(--primary-color) !important;
    }
    .sd-action-bar .sv-action:not(:last-child) .sv-action__content{
        margin-bottom: 1em !important;
    }
    .sd-context-btn{
        margin-bottom: 1em !important;
    }

}
@media screen and (min-width: 375px) and (max-width: 430px) {
    .sd-action-bar .sv-action__content {
        margin-right: 0 !important;
        width: 100%;
    }
    .sd-action-bar .sv-action:not(:last-child) .sv-action__content {
        width: 100%;
        margin-right: 0em !important;
    }
    .sd-action-bar .sv-action__content:last-child {
        // margin-right: 10em !important;
        width: 50%;
    }
    .sv-components-column {
        flex-direction: row !important;
        justify-content: space-between !important;
    }
}
@media screen and (max-width: 1112px) {
    .sd-file__actions-container{
        justify-content: end !important;
    }
}

#sv-nav-next, #sv-nav-prev {
    .sd-btn, #save_submit_user_button{
        padding-top: 10px!important;
        padding-bottom: 10px!important;
        color:#fff !important;
        background-color: var(--primary-color) !important;
        margin-bottom: 20px !important;
        width: auto;
        font-family: $inter, sans-serif!important;
    }
    .sd-btn:hover {
        background-color: #010937 !important;
    }
    @media screen and (min-width: 360px) and (max-width: 834px){
        .sd-btn{
            width: auto;
            // margin: 1em !important;
        }
    }
    
    @media screen and (min-width: 761px) and (max-width: 1024px){
        .sd-btn{
            width: auto;
        }
    }
}

.sd-item__decorator {
    width: 18px!important;
    height: 18px!important;
    background-color: #fff !important;
    
    // box-shadow: none !important;
}
@media screen and (max-width: 375px) {

    .sd-matrix {
        .sd-table__row:nth-child(even) {
            border-color: transparent !important;
            background-color: #fff !important;
        }
        .sd-matrix__table tr:nth-child(2n) .sd-matrix__cell {
            background-color: #fff !important;
    
        }
    }
}
@media screen and (min-width: 1113px) {
    .sd-container-modern__title{
        box-shadow: none !important;
        border-bottom: none !important;
        padding: 40px 48px 0 54px !important;
    }
}

.sd-root-modern {
    background-color: transparent !important;
}
.sd-root-modern:not(.sd-root-modern--mobile) .sd-table__cell:not(.sd-table__cell--actions):not(.sd-table__cell--action):not(.sd-table__cell--empty.sd-table__cell--error){
    border: 0 !important;
    // border-radius: 8px !important;
}

.sd-root-modern:not(.sd-root-modern--mobile) .sd-table--alternate-rows{
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
#assessment_container {
    input[title="Complete"] {
        display: none !important;
    }
}
mat-label, mat-error {
    font-family: $inter, sans-serif!important;
}    
mat-card{
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1) !important;
}
//for cont colors
.font-orange{
    color: var(--secondary-color);
}

vc-new-initial-assesment {
    mat-card-subtitle, mat-card-title {
        font-family: $inter, sans-serif!important;
        justify-content: center;
    }
    mat-card-subtitle {
        display: block!important;
        width: 100%;
    }
    mat-card-actions {
        justify-content: flex-end;
    }
    .primary{
        height: 44px!important;
        background-color: var(--primary-color)!important;
        padding: 10px!important;
        border-radius: 50px!important;
        display: flex!important;
        align-items: center!important;
        justify-content: center!important;
        font-weight: 500; /* Medium weight */
        font-size: 14px; /* Font size of 14 pixels */
        letter-spacing: 0.03em; /* 3% letter spacing */
        text-decoration: none; /* No text decoration */
        line-height: normal; /* Automatic line height */
        text-transform: none; /* Original case */
        background-color: $rws-primary!important;
        color: #fff!important;
        border: none!important;
        min-width: 200px;
        
    }
}

.form-group{
    margin: 0 !important;
}

mat-tab-header {
    .mat-mdc-tab-labels {
        gap: 16px!important;
        // background-color: #08135129;
        @media (max-width:667px){
           
                display: flex;
                flex-direction: column;
        }
        div{
            background-color: rgba(8, 19, 81, 0.08);
            border-radius: 8px 8px 0 0 ;
            width: 0% !important;
            @media (max-width:667px){
                width: 100%!important;
                border-radius: 100px !important;

                
        }
          }
    }
    
    .mat-tab-group {
        flex-grow: 1;
    }
   
    span {
        color: var(--primary-color) !important;
        border-radius: 8px 8px 0 0!important;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: normal !important;
        font-family: $inter, sans-serif!important;
    }
    span.mdc-tab-indicator {
        background: none!important;
    }
    .mdc-tab-indicator--active{
        background: var(--secondary-color) !important;
        border-radius: 8px 8px 0 0!important;
        @media (max-width: 360px){
            width: 100%;
        }
        
        span {
            color: #fff!important;
            border: none;
        }
    }
}
.mat-mdc-tab-body-wrapper{
    border-radius: 0px 0px 12px 12px !important;
}

.form-group{
    margin: 0 !important;
    .mat-mdc-tab-body {
        padding: 24px !important;
  }
}
mat-drawer-container {
    color:var(--primary-color) !important;
    min-height: 700px;

    .mat-drawer-shown{
      background-color: rgba(0, 0, 0, 0) !important;
    }
    
  }
  mat-drawer .mat-drawer-inner-container {
    border-radius: 12px !important;
    width: 95% !important;
    scrollbar-width: none;
    .mat-drawer-transition .mat-drawer-backdrop {
        width: 32% !important;
    }
    
    .mat-drawer {
        overflow: visible !important; /* Ensures the drawer's height grows with content */
      }
      
      .inner-padding {
        display: flex;
        flex-direction: column;
      }
      
      .mat-accordion {
        width: 100%;
      }
      
      .mat-expansion-panel {
        overflow: visible !important;
      }
      
      .mat-chip-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }
      .drawer-style {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }
    
    .drawer-content {
        display: flex;
        flex-direction: column;
        height: auto;
    }
    
    .mat-tab-group {
        flex-grow: 1;
    }
    
    
    
    .mat-card {
        width: 100%;
        max-width: 400px;
    }
    
    .mat-drawer-container {
        height: auto !important;
    }
    .filter-chip{
        
            span .mdc-evolution-chip__action{
                cursor:  pointer !important;
            }
        
    }
    

  }
  
  mat-drawer-content{
    .custom-tab-group{
        .mat-mdc-tab-body-wrapper{
            .card-grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 24px;
            }
            
            .card-grid-expanded {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 24px;
                // padding: 16px;
                @media (max-width: 1180px){
                    grid-template-columns: repeat(2, 1fr);
                }
                @media (max-width: 480px){
                    grid-template-columns: repeat(1, 1fr);
                }
            }

            
        }
    }
    .card-grid-global {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
    }
    .card-grid-expanded {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
        // padding: 16px;
        @media (max-width: 1180px){
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 480px){
            grid-template-columns: repeat(1, 1fr);
        }
    }
  }
  mat-drawer .mat-drawer-side {
    border-right: none !important;
}

  mat-accordion{
   
    mat-expansion-panel {
            border-bottom: 0 !important;
            border-right: 0 !important;
            border-top-right-radius: 0 !important;
            border-top-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
    
            padding: 12px 0;
    
            .mat-accordion .mat-expansion-panel:first-of-type {
                border-top-right-radius: 0 !important;
                /* Adding !important to ensure it overrides */
                border-top-left-radius: 0 !important;
                /* Adding !important to ensure it overrides */
            }
    
            .mat-accordion .mat-expansion-panel:last-of-type {
                border-bottom-right-radius: 0 !important;
                /* Adding !important to ensure it overrides */
                border-bottom-left-radius: 0 !important;
                /* Adding !important to ensure it overrides */
            }
            .mat-expansion-panel-content{
                .mat-expansion-panel-body {
                    padding: 0 !important;
                    @media screen and (max-width:834px){
                        width: 295px;
                        
                    }
                }
            }
    
        }
      span{
        .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
            // color:var(--primary-color) !important;
        
            }
             .mat-accordion .mat-expansion-panel:first-of-type {
        border-top-right-radius: 0 !important; /* Adding !important to ensure it overrides */
        border-top-left-radius: 0 !important;  /* Adding !important to ensure it overrides */
    }
    
    .mat-accordion .mat-expansion-panel:last-of-type {
        border-bottom-right-radius: 0 !important; /* Adding !important to ensure it overrides */
        border-bottom-left-radius: 0 !important;  /* Adding !important to ensure it overrides */
    }
    }
  }

  mat-chip{
    background-color: var(--secondary-color);
    margin: 7px 7px 0 0;

    span{
        // .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
            color: var(--primary-color) !important;   
            font-size: 12px !important;
            font-weight: 500 !important;  
            // }
            
    }
    .chip-style{
        border: none !important;
    }
    
  }
  mat-chip.active {
    background-color: var(--primary-color) !important;
    padding-right: 25px;
    &::after {
        content: '';
        display: block;
        background: url(./../assets/icons/check.png);
        background-size: contain;
        background-repeat: no-repeat;
        width: 12px;
        height: 12px;
        position: absolute;
        right: 15px;
        top: 11px;
    }
    span{
        color: #fff!important;
    }
  }
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
     color: var(--primary-color) !important; 
   }
  .mat-expansion-panel {
    color: var(--primary-color) !important;
    box-shadow: none !important;
    border: 0 !important;
    // border-top: 1px solid #7D8A95 !important;
    border-bottom: 1px solid #7D8A95 !important;

  }

.mat-mdc-tab-body-wrapper{
    border-radius: 0px 0px 12px 12px !important;
    background-color: rgba(8, 19, 81, 0.04);
}
span{
    font-family: $inter, sans-serif!important;
}
p{
    font-family: $inter, sans-serif!important;
    margin: 0 !important;
}
mat-chip .mat-mdc-chip-focus-overlay {
    background: transparent !important;
}

.paginator-bg{
    background-color:  transparent !important;
}

//recommended resources mat-card

.recommended-resources {
    display: flex;
    // justify-content: space-between;
    flex-direction: row;
    gap: 16px;
}

.mat-card .mat-card-image-style {
    width: 100%;
}

.p-style {
    font-size: 14px !important;
    font-weight: 400 !important;
    letter-spacing: 0.03em !important;
    color: #7D8A95 !important;
    margin: -1em 0 0 0 !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2; /* Number of lines to show */
    text-overflow: ellipsis;
    height: 40px;
}
.dot-style{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1; /* Number of lines to show */
    text-overflow: ellipsis;
}

.font-color {
    color: var(--primary-color)
}

.continue-button {
    width: 100%;
}

.arrow-width {
    width: 24px !important;
    height: 24px !important;
    margin-left: 8px;
}
@media screen and (max-width: 360px) {
    .recommended-resources, .route {
        flex-direction: column;
  }
}
.InitialAssess{
    div[data-name="selectthemostrelevantanswer"] {
        display: none !important;
    }
}

/*style for message*/
.get-started-button{
    min-width: 200px;
    margin-top: 20px;
}
.newChat{
    background: #00A09B;
    min-width: 200px;
    margin-top: 30px;
    mat-icon{
        font-size: 18px;
        height: 18px;
        width: 18px;
        margin-top: 3px;
    }
}
.newChatPosition {
    position: absolute;
    bottom: 24px;
    width: calc(100% - 48px);
}
.auto-message-container {
    margin-left: 60px;
    .auto-message{
        border: 1px solid #EBEBEB!important;
        padding: 6px 16px 6px 16px;
        border-radius: 12px;
        background-color: #fff!important;
        margin-top: 15px;
        margin-bottom: 0!important;
        display: inline-block!important;
        span {
            color: #081351!important;
            padding: 0!important;
            margin: 0;
        }
        a {
            text-decoration: none;
        }
    }
}
button:disabled {
    background-color: rgba(8, 19, 81, 0.08)!important;
    color: #7D8A95!important;
}
@media screen and (max-width: 1024px) {
    mat-drawer {
        .mat-drawer-inner-container {
            width: 100%!important;
        }
    }
    .newChat {
        min-width: 150px;
        position: relative;
        padding-right: 25px;
        mat-icon {
            position: absolute;
            margin-top: 0px;
        }
    }
}
mat-table{
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1) !important;
}


mat-hint, mat-error{
    margin-left: -16px !important;
}
mat-table{
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1) !important;
}
.mdc-tab{
    letter-spacing: 0 !important;
}

p{
    margin: 0 !important;
}
.sd-radio--checked {
    .sd-radio__decorator:after{
        background-color: #fff!important;
    }
}
.sd-action-bar {
    display: block!important;
    #sv-nav-prev {
        float: left!important;
        @media screen and (max-width: 1024px) {
            width: 100%;
        }
    }
    #sv-nav-next {
        float: right!important;
    }
    @media screen and (max-width: 1024px) {
        display: flex !important;
        width: 100% !important;
    }
}

@media screen and (max-width: 1024px) {
    mat-drawer {
        .mat-drawer-inner-container {
            width: 100%!important;
        }
    }
}
mat-table{
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1) !important;
}


.mat-mdc-tab-body-wrapper{
    border-radius: 0px 0px 12px 12px !important;
}
p{
    margin: 0 !important;
}

.mat-mdc-tab-body-wrapper{
    border-radius: 0px 0px 12px 12px !important;
}
p{
    margin: 0 !important;
}
html{
    top: 0px !important;
}
@media screen and (max-width: 1024px) {
    .newChat {
        min-width: 150px;
        position: relative;
        padding-right: 25px;
        margin-top: 37px;
    }
    .onlyMobile {
        mat-icon {
            position: absolute;
            margin-top: 0px;
        }
    }
}
@media screen and (max-width: 480px) {
    .messagesBlock{
        display: none;
    } 
    
 }


.sd-body.sd-body--static{
    max-width: 100% !important;
}


//body medium text
.medium{
    font-family: $inter, sans-serif;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: 0.01em !important; /* 1% letter spacing */
    color: #7D8A95;
}
@media screen and (max-width: 375px) {
    .pdf-style {
        .left-half{
            min-width: 355px;
            margin-left: -22px;
            .lower-content {
                padding: 24px 0;
                .pdf-bottom {
                    padding: 0 24px;
                }
            }
        }
    }
}
@media screen and (max-width: 375px) {
    .pdf-style {
        .left-half{
            min-width: 355px;
            margin-left: -14px;
        }
    }
}
mat-error {
    &::before {
        display: none!important;
    }
    margin-top: 10px;
}

//add-icon 
.add-icon{
    width: 26.67px;
    height: 26.67px;
}
.pie-chart {
    .apexcharts-canvas{
        padding-left: 47px!important;
    }
}    
.mat-mdc-snack-bar-container {
    .mdc-snackbar__surface {
        background-color: var(--primary-color)!important;
        border-radius: 16px!important;
        padding-right: 0 !important;
        min-width: 295px !important;
    }
    .mdc-button__label{
        color: #fff!important;
    }
    .mdc-snackbar__actions {
        border-left: 1px solid #FFFFFF3D!important;
        .mdc-button{
            min-width: 50px !important;
        }
    }
    .mdc-snackbar__label{
        // margin-left: 2em;
        padding: 16px 0 16px 24px !important;
    }
    
}
.flex-container{
    .mdc-text-field--outlined {
        .mdc-notched-outline {
            .mdc-notched-outline__leading, .mdc-notched-outline__trailing {
                border-color: #D1D5DB!important;
            }
        }
    }
}
.mdc-text-field--outlined {
    .mdc-notched-outline {
        .mdc-notched-outline__leading, .mdc-notched-outline__trailing {
            border-color: #D1D5DB!important;
        }
    }
}
a {
    &:focus {
        //box-shadow: 0px 0px 4px 0px rgba(255, 211, 152, 1);
        border-radius: 7px;
        outline: 2px solid #999;
        outline-offset: 2px;
    }
}
#fileInput {
    &:focus {
        + .center-content {
            border-radius: 7px;
            outline: 2px solid #999;
            outline-offset: -2px;
        }
    }
}
@media screen and (max-width: 1024px) {
    .buttons-div{ 
        // right: 0px!important;
        // bottom: 40px!important;
        // width: 100% !important;
        // padding: 0 2em;
        button {
        width: 100%!important;
        margin: 0 12px!important;
        padding-bottom: 10px !important;
        }
    }
    .sd-body__navigation.sd-action-bar {
        gap: 0 !important;
    }
}
@media screen and (max-width: 1112px) {
    .sd-table__cell--actions:not(.sd-table__cell--vertical):first-of-type, .sd-table__cell--empty:first-of-type, .sd-table__cell--row-text:first-of-type, .sd-table__cell--footer-total:first-of-type, .sd-matrix__cell:first-of-type:first-of-type, .sd-matrix tr>td:first-of-type:first-of-type{
        left: calc(-1*(var(--sjs-base-unit, var(--base-unit, 40px))))!important;
    }
}



.sd-imagepicker{
    // gap: 24px !important;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.sd-imagepicker__item-decorator{
    .sd-imagepicker__image-container{
        // width: 300px !important;
        // height: 200px !important;
        img{
        //     width: 300px !important;
        // height: 200px !important;
        object-fit: cover !important;
        }
    }
    .sd-imagepicker__image-container{
        .sd-imagepicker__check-decorator{
            background-color: var(--secondary-color) !important;
            width: 26px !important;
            height: 26px !important;
            .sd-imagepicker__image{
                // width: 300px !important;
                // height: 200px !important;
            }
        }
    }
}
.sd-imagepicker__item{
    border: 3px solid transparent;
    // width: 300px !important;
    // height: 200px !important;
}
.sd-imagepicker__item--checked{
    border: 3px solid var(--secondary-color) !important;
    border-radius: 6px !important;
}
.sd-file{
    .sd-file__decorator{
        border-radius: 6px;
    }
}
#sv-file-choose-file{
    .sd-context-btn:hover use{
        fill: #7D8A95 !important;
    }
}

.sd-item__control-label{
    .sv-string-viewer{
        color: #7D8A95 !important;
        font-weight: 500 !important;
        display: inline-block;
    }
}
.sd-row{
    div{
        div .sd-element .sd-element--with-frame .sd-question .sd-question--title-top .sd-row__question{
            border: none !important;
            padding: 0 !important;
        }
    }
    
}
.sd-question__content{
    .sd-input{
        box-shadow: none !important;
        border: 1px solid #EBEBEB !important;
        background-color: transparent !important;
        color: var(--primary-color) !important;
    }
}

.sd-image{
    .sd-image__image{
        border-radius: 6px !important;
        border: 1px solid #D9D9D9 !important;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08) !important;
    }
}
#assessment_container{
    .sd-element--with-frame{
        border: none !important;
        box-shadow: none !important;
        padding: 0 !important;
        margin-bottom: 20px;
    }
    .sd-element--complex>.sd-element__header:after{
        background-color: #fff!important;
    }
    
}
.startAssessment{
    .sd-question__content{
        // margin-left: -2em !important;
    }
    .sd-table-wrapper{
        width: unset !important;
        min-width: unset !important;
        // padding: 0 3em !important;
        // margin-left: -1em;
    }
    .sd-row > div:first-child {
        overflow: visible !important;
    }
    
    @media screen and (max-width:1113px) {
        .sd-row > div:first-child {
            overflow: auto !important;
        }
        
    }
}
.assess-ui{
    .sd-table-wrapper {
        width: unset !important;
        min-width: unset !important;
        padding: 0 3em !important;
    }
    .sd-root-modern.sd-root-modern--full-container{
        overflow: visible !important;
    }
    .sd-element--complex.sd-element--with-frame>.sd-element__header{
        padding-top: 0 !important;
    }
    .sd-question--empty.sd-question--paneldynamic>.sd-question__content {
        padding-bottom: 0 !important;
    }
}
vc-new-initial-assesment{
    .sd-root-modern.sd-root-modern--full-container{
        overflow: visible !important;
    }
}


//for small devices
@media screen and (max-width:1112px) {
    .sd-body.sd-body--responsive{
        padding: 0 1em !important;
        padding-bottom: 60px !important;
    }
    .sd-element--complex>.sd-element__header:after{
       display: none !important;
    }
    .sd-root-modern.sd-root-modern--full-container {
        overflow: visible !important;
    }
}
@media screen and (max-width:910px) {
    .sd-imagepicker {
        grid-template-columns: repeat(1, 1fr);
    }
    
}
@media screen and (min-width:901px) and (max-width:1111px) {
    .sd-imagepicker {
        grid-template-columns: repeat(2, 1fr);
    }
    
}
a {
    &:focus {
        box-shadow: 0px 0px 4px 0px rgba(255, 211, 152, 1);
        border-radius: 7px;
    }
}


#save_submit_user_button{
    font-weight: 600 !important;
    font-size: 16px !important;
    font-family: $inter, sans-serif!important;
    width: 192px !important;
}

@media screen and (max-width: 1111px) {
    #save_submit_user_button {
      width: 100% !important;
    }
}
.sd-table-wrapper{
    display: block;
    margin: 0 !important;
    min-width: 100% !important;
}
@media screen and (max-width: 1112px) {
    .retake_assessment{
        .sd-question.sd-question--table {
            overflow-x: auto !important;
        }
    }
}

@media screen and (min-width: 1040px) {
    .buttons-div{ 
       margin-left: 30rem !important;
        }
    }
    
#save_submit_user_button{
    font-weight: 600 !important;
    font-size: 16px !important;
    font-family: $inter, sans-serif!important;
}

.apexcharts-menu-item{
    float: left !important;
}


//Userway Accessibility

//dark contrast
.userway-s3-2{
    .apexcharts-legend-text{
        padding-left: 0 !important;
        margin-left: 0 !important;
    }
    .mat-mdc-standard-chip {
        background-color: #000 !important;
    }
    .mat-mdc-chip-primary-focus-indicator{
        display: none !important;
    }
    main{
        background-image: none;
    }
    button{
        border: 1px solid !important;
    }
    .toggle-button, .userway-button
    {
        border: none !important;
    }
    button:focus{
        outline: none !important;
    }
    .userway-award{
        border-right: 1px solid #fff !important;
    }
    mat-tab-header .mdc-tab-indicator--active, .docIcon{
        filter: invert(1) !important;
    }
   
    mat-chip .mat-mdc-chip-ripple{
        border: 1px solid !important;
        border-radius: 16px !important;
    }
    .sd-matrix .sd-table thead tr td:nth-child(odd) {
        // border-top: 1px solid !important;
        border-bottom: none !important;
    }

    .sd-matrix .sd-item--checked .sd-item__decorator, .sd-matrix .sd-item__control:focus+.sd-item__decorator, .sd-matrix .sd-item--checked .sd-item__decorator {
        filter: none !important;
    }
    .sd-matrix .sd-radio__decorator {
        filter: contrast(0.5);
    }
    #sv-nav-next, #sv-nav-prev {
        .sd-btn, #save_submit_user_button{
            border: 1px solid !important;
        }
    }
    .message-list-left{
        border-right: 1px solid !important;
        border: 1px solid !important;
        border-radius: 16px 0 0 16px !important;
        height: calc(100% - 96px) !important;
    }
    .message-list-right{
        border: 1px solid ;
        border-left: none !important;
        border-radius: 0 16px 16px 0 !important;
    }
    .userway-cardStyle{
        border: none !important;
        border-radius: 0 !important;
    }
    .progress-bar{
        filter: invert(1) !important;
    }
    .progress{
        filter: contrast(0.3);
    }
    
}

//light contrast
.userway-s3-3{
    .apexcharts-legend-text{
        padding-left: 0 !important;
        margin-left: 0 !important;
    }
    .mat-mdc-standard-chip {
        background-color: #000 !important;
    }
    .mat-mdc-chip-primary-focus-indicator{
        display: none !important;
    }

    .messagesBlock{
        svg {
            filter: invert(1);
        }
    }
    .message-list-right{
        border-left: 0 !important;
    }
    main{
        background-image: none;
    }
    button{
        border: 1px solid !important;
    }
    .toggle-button, .userway-button, .auto-message-container .auto-message {
        border: none !important;
    }
    button:focus{
        outline: none !important;
    }
    .userway-award{
        border-right: 1px solid #000 !important;
    }
    .headerSection .appLogoSection img, .arrow-width{
        filter: invert(1) !important;
    }
    mat-tab-header .mdc-tab-indicator--active, .arrow-icon {
        filter: invert(1) !important;
    }
    mat-chip .mat-mdc-chip-ripple{
        border: 1px solid !important;
        border-radius: 16px !important;
    }
    .sd-container-modern {
        padding: 0 47px !important;
    }
    .sd-matrix .sd-table thead tr td:nth-child(odd) {
        border-bottom: none !important;
    }
    .sd-matrix .sd-radio__decorator {
        filter: contrast(0.9);

    }
    #sv-nav-next, #sv-nav-prev {
        .sd-btn, #save_submit_user_button{
            border: 1px solid !important;
        }
    }
    .userway-create-message{
        border: 1px solid !important;
        border-radius: 16px !important;
    }
    .message-list-left{
        border-right: 1px solid !important;
        border: 1px solid !important;
        border-radius: 16px 0 0 16px !important;
        height: calc(100% - 96px) !important;
    }
    .message-list-right{
        border: 1px solid ;
        border-left: none !important;
        border-radius: 0 16px 16px 0 !important;
    }
    .userway-cardStyle{
        border: none !important;
        border-radius: 0 !important;
    }
    .progress-bar{
        filter: contrast(0.3) !important;
    }
    .progress{
        filter: invert(1);
    }

}

//bigger text
[data-userway-s4-zoom-styled="true"] 
  .messages-page .messages-container .message-list-right 
  .bottom-contents .message-input-container {
    width: 200px !important; 
    margin-bottom: 2em !important;
}
[data-userway-s4-zoom-styled="true"] 
  .messages-page .messages-container .message-list-right 
  .bottom-contents .button-alignment {
    float: none !important;
}
[data-userway-s4-zoom-styled="true"] 
  .messages-page .messages-container .message-list-right 
  .bottom-contents .button-alignment .button {
    margin: 2em 0 0 0 !important;
}
[data-userway-s4-zoom-styled="true"]
    .mainContainSection{
        overflow-x: auto !important;
    }

[data-userway-s4-zoom-styled="true"]
    .resources-and-quiz{
        width: max-content;
    }

[data-userway-s4-zoom-styled="true"]
    .width-userway{
        width: auto !important;
}

[data-userway-s4-zoom-styled="true"]
.assess-ui .sd-table-wrapper {
    width: 58% !important;
    min-width: 300px !important;
    overflow-x: scroll !important;
}

